var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { alpha } from '@mui/material/styles';
//
import { CloseIcon } from './CustomIcons';
// ----------------------------------------------------------------------
var COLORS = ['primary', 'secondary', 'info', 'success', 'warning', 'error'];
export default function Chip(theme) {
    var isLight = theme.palette.mode === 'light';
    var rootStyle = function (ownerState) {
        var defaultColor = ownerState.color === 'default';
        var filledVariant = ownerState.variant === 'filled';
        var outlinedVariant = ownerState.variant === 'outlined';
        var softVariant = ownerState.variant === 'soft';
        var defaultStyle = __assign({}, (defaultColor && __assign(__assign({ '& .MuiChip-avatar': {
                color: theme.palette.text[isLight ? 'secondary' : 'primary'],
                backgroundColor: alpha(theme.palette.grey[500], 0.48),
            } }, (outlinedVariant && {
            border: "solid 1px ".concat(alpha(theme.palette.grey[500], 0.32)),
        })), (softVariant && {
            color: theme.palette.text.primary,
            backgroundColor: alpha(theme.palette.grey[500], 0.16),
            '&:hover': {
                backgroundColor: alpha(theme.palette.grey[500], 0.32),
            },
        }))));
        var colorStyle = COLORS.map(function (color) { return (__assign({}, (ownerState.color === color && __assign(__assign({ '& .MuiChip-avatar': {
                color: theme.palette[color].lighter,
                backgroundColor: theme.palette[color].dark,
            } }, (filledVariant && {
            '& .MuiChip-deleteIcon': {
                color: alpha(theme.palette[color].contrastText, 0.56),
                '&:hover': {
                    color: theme.palette[color].contrastText,
                },
            },
        })), (softVariant && {
            color: theme.palette[color][isLight ? 'dark' : 'light'],
            backgroundColor: alpha(theme.palette[color].main, 0.16),
            '&:hover': {
                backgroundColor: alpha(theme.palette[color].main, 0.32),
            },
            '& .MuiChip-deleteIcon': {
                color: alpha(theme.palette[color][isLight ? 'dark' : 'light'], 0.48),
                '&:hover': {
                    color: theme.palette[color].dark,
                },
            },
        }))))); });
        return __spreadArray(__spreadArray([], colorStyle, true), [defaultStyle], false);
    };
    return {
        MuiChip: {
            defaultProps: {
                deleteIcon: _jsx(CloseIcon, {}),
            },
            styleOverrides: {
                root: function (_a) {
                    var ownerState = _a.ownerState;
                    return rootStyle(ownerState);
                },
            },
        },
    };
}
